import "./App.css";
import "./pageStyle.css";
import React, { Suspense, lazy, useRef, useState } from "react";
const ImagePreview = lazy(() => import("./ImagePreview"));
const Base64Encoder = lazy(() => import("./Base64Encoder"));
const UuidConvertor = lazy(() => import("./UuidConvertor"));
function App() {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("Encoder");
  const [base64Images, setBase64Images] = useState([""]);
  const [selectedImage, setSelectedImage] = useState(null);
  const toogleHandler = (text) => {
    setActiveTab(text);
  };
  const handleFileChange = (event) => {
    setLoading(true);
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Images((prev) => [base64String, ...prev]);
      };

      reader.readAsDataURL(file);
    }
    setLoading(false);
  };

  const handleCopyToClipboard = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        setTimeout(() => {
          setIsCopied(true);
        }, 100);
        setIsCopied(false);
        console.log("Text successfully copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text to clipboard:", err);
      });
  };

  const openImagePreview = (image) => {
    setSelectedImage(image);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <input
        ref={ref}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(event) => handleFileChange(event)}
      />
      <header>
        <h1>ATF</h1>
        <p>All Tech Frame</p>
        <nav>
          <a target="_blank" href="https://alltechframe.com/">
            Home
          </a>
          <a
            target="_blank"
            href="https://alltechframe.com/solvexperience-journey/"
          >
            About Us
          </a>
        </nav>
      </header>
      <div
        style={{
          gap: "20px",
          margin: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          style={
            activeTab === "Encoder"
              ? {
                  backgroundColor: "white",
                  color: "var(--primary-color)",
                  border: "0.1px solid var(--primary-color)",
                }
              : {}
          }
          onClick={() => {
            toogleHandler("Encoder");
          }}
        >
          Encoder
        </button>
        <button
          style={
            activeTab === "Decoder"
              ? {
                  backgroundColor: "white",
                  color: "var(--primary-color)",
                  border: "0.1px solid var(--primary-color)",
                }
              : {}
          }
          onClick={() => {
            toogleHandler("Decoder");
          }}
        >
          Decoder
        </button>
        <button
          style={
            activeTab === "UUID"
              ? {
                  backgroundColor: "white",
                  color: "var(--primary-color)",
                  border: "0.1px solid var(--primary-color)",
                }
              : {}
          }
          onClick={() => {
            toogleHandler("UUID");
          }}
        >
          UUID Generator
        </button>
      </div>
      {activeTab === "Encoder" ? (
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <center>
                    <button
                      type="button"
                      onClick={() => {
                        ref?.current?.click();
                      }}
                    >
                      Select Image
                    </button>
                  </center>
                </td>
              </tr>
              {loading ? (
                <p style={{ textAlign: "center" }}>Loading...</p>
              ) : (
                <Suspense fallback={"Loading..."}>
                  <ImagePreview
                    isCopied={isCopied}
                    base64Images={base64Images}
                    openImagePreview={openImagePreview}
                    handleCopyToClipboard={handleCopyToClipboard}
                  />
                </Suspense>
              )}
            </tbody>
          </table>
        </div>
      ) : activeTab === "Decoder" ? (
        <Suspense fallback={"Loading..."}>
          <Base64Encoder />
        </Suspense>
      ) : (
        <Suspense fallback={"Loading..."}>
          <UuidConvertor />
        </Suspense>
      )}

      {selectedImage && (
        <div className="image-preview">
          <span className="close-btn" onClick={closeImagePreview}>
            &times;
          </span>
          <img src={selectedImage} alt="Preview" />
        </div>
      )}
    </>
  );
}

export default App;
